import React, { useEffect } from "react";

import { object, number } from "prop-types";

import StoryElements from "../story-elements";

const ImmersiveStory = ({ story, index, fontSize }) => {
  useEffect(() => {
    if (story["story-template"] === "immersive") {
      let headerElement, footerElement;
      if (document) {
        headerElement = document.getElementsByClassName("top-bar-container");
        footerElement = document.getElementById("footer");

        if (headerElement.length > 0 && footerElement) {
          headerElement[0].style.display = "none";
          footerElement.style.display = "none";
        }
      }

      if (headerElement.length > 0 && footerElement) {
        return () => {
          headerElement[0].style.display = "block";
          footerElement.style.display = "block";
        };
      }
    }
  }, []);
  return <StoryElements story={story} cards={story.cards} index={index} isImmersiveStory={true} fontSize={fontSize} />;
};

ImmersiveStory.propTypes = {
  story: object,
  index: number
};

export default ImmersiveStory;
